@import "vars.scss";
.InputContainer {
    &.error {
        [class~=child] {
            > * {
                border-color: $warning;
            }
        }
        [class~=select__control ] {
                border-color: $warning;
        }
    }
  margin-bottom: 24px;
  label {
    display: block;
  }
  input {

  }
  &[class~=error] {
    [class~=select__control] {
      border-color: $warning !important;
    }
  }
  &[class~=merged] {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid $border;
    padding-left: 12px;
    label {
      white-space: nowrap;
      margin: 0;
    }
    input {
      border: 0;
    }
    &:focus-within {
      border-color: darken($border, 10%);
    }
  }
}
.merged {
  position: relative;
  label {
    position: absolute;
  }
}
.inputChild {
  display: flex;
  flex-direction: column-reverse;
}
.errorMsg {
  color: $warning;
}
.InputDesc {
  font-size: 13px;
  color: $desc;
}
.react-select {
  &__option {
    display: block;
    font-size: 16px;
    color: rgb(36, 36, 36);
    padding: 11px 12px;
    width: 100%;
    user-select: none;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      background-color: #EBECF0;
    }
    &_selected {
      background-color: $cyan;
      color: #FFFFFF;
      &:hover {
        background-color: $cyan;
      }
    }
  }
}
.type-spirit:before, .type-ingredient:before {
  content: "";
  width: 24px;
  height: 24px;
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  border-radius: 50%;
}
.type-spirit {
  &:before {
    background-image: url("/img/icons/bottle.svg");
    background-color: rgba($warning, 25%);
    margin-right: 8px;
  }
}
.type-ingredient {
  &:before {
    background-image: url("/img/icons/lemon-light.svg");
    background-color: rgba($success, 25%);
    margin-right: 8px;
    background-size: 12px;
  }
}
.multiSelectOption {
  > div {
    background-color: $baby;
    border: 1px solid lighten($cyan, 50%);
    font-weight: 600;
    font-size: 14px;
    > div:last-of-type{
      cursor: pointer;
    }
  }
}
.horizontalSlider {
  background-color: #000;
  margin-top: -3px;
  > div:nth-of-type(2) {
    opacity: .2 !important;
  }
}
.horizontalSliderTrack {
  background-color: $cyan;
  height: 6px;
  border-radius: 20px;
  &:last-of-type, &-1 {
    opacity: .5;
  }
}
.horizontalSliderThumb {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #ddd;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  margin-top: -13px;
  cursor: pointer;
  font-size: 15px;
  box-shadow: $shadow;
}
.singleImageUpload {
  display: block;
  min-width: 300px;
  &_container {
    position: relative;
    border: 1px solid $border;
  }
  &_loader {
    height: 6px;
    background-color: $cyan;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  &_image {
    position: relative;
    background-image: url("/img/icons/image-light.svg");
    background-size: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    padding-bottom: 100%;
    img {
      position: absolute;
      object-fit: cover;
      object-position: center center;
      height: 100%;
      width: auto;
    }
  }
  &_actions {
    display: flex;
    border-top: 1px solid $border;
    > span {
      flex-grow: 1;
      text-align: center;
      border-left: 1px solid $border;
      opacity: .75;
      label {
        padding: 12px;
        cursor: pointer;
        margin-bottom: 0 !important;
        font-size: 16px !important;
      }
      &:hover {
        opacity: 1;
      }
      &:first-of-type {
        border-left: 0;
      }
    }
  }
}