@import "vars.scss";
.logoHolder {
	width: auto;
	height: 80px;
    padding: 16px 0;
    fill: $blue;
    @media (max-width: 1080px) {
    	height: 70px;
    }
    @media (max-width: 767px) {
    	height: 60px;
    	padding: 8px 0;
    }
	svg {
		height: 100%;
		width: auto;
		max-width: 100%;
	}
}
.homeMenu {
	position: fixed;
	display: block;
	top: 0; right: 0; left: 0;
	color: $dark;
	z-index: 1234;
	width: 100%;
	background-color: #fff;
	box-shadow: $shadow;
	&.horeca {
		display: none;
	}
	.container {
		display: flex;
		> div:nth-of-type(1) {
			display: flex;
			flex-grow: 1;
		}
	}
	ul {
		display: flex;
		justify-content: space-around;
		align-items: center;
		@media (max-width: 1080px) {
			display: block;
		}
		> li {
			margin-left: 24px;
			&.mobileOnly {
				@media (min-width: 1081px) {
					display: none;
				}
			}
			a {
				display: block;
				padding: 12px 0;
				cursor: pointer;
				color: $dark;
				font-weight: 400;
				white-space: nowrap;
				width: 100%;
				&.active {
					color: red;
				}
				&:hover {
					text-decoration: underline;
				}
				svg {
					margin-left: 6px;
					font-size: 12px;
				}
			}
			> ul {
				display: none;
				position: absolute;
				background-color: #fff;
				top: 100%;
				width: 100vw;
				left: 0;
				box-shadow: 0px 18px 20px -2px rgba(0,0,0,.15);
			}
			&:hover {
				> ul {
					@media (min-width: 1081px) {
						display: flex;
						li {
							margin: 0;
							a {
								font-weight: 600;
							}
							> ul {
								display: block;
								position: relative;
							    top: auto;
							    width: auto;
							    left: auto;
								li a {
									font-weight: 300;
								}
							}
						}
					}
				}
			}
		}
	}
}
.menuHolder {
	padding: 0px;
	display: flex;
	align-items: center;
	@media (max-width: 1080px) {
		position: fixed;
		top: 70px;
		left: 0;
		bottom: 0;
		width: 100%;
		justify-content: center;
		background-color: $greige;
	}
    @media (max-width: 767px) {
    	top: 60px;
    	align-items: flex-end;
    }
	> div {
		@media (max-width: 1080px) {
			height: 100%;
		    overflow: auto;
		    padding-top: 30px;
		    padding-bottom: 50px;
		}
		@media (min-width: 1081px) {
			display: flex;
			height: 100%;
		}
	}
	ul {
		
		li {
			@media (max-width: 1080px) {
				margin: 0;
			}
			a {
				@media (max-width: 1080px) {
					font-size: 22px;
					font-weight: bold;
				}
				@media (max-height: 768px) {
					font-size: 18px;
					padding-top: 8px;
					padding-bottom: 8px;
				}
				svg {
					@media (max-width: 1080px) {
						display: none;
					}
				}
			}
		}
	}
	> div > ul > li {
		height: 100%;
		display: flex;
		align-items: center;
	}
	&.closed {
		@media (max-width: 1080px) {
			display: none;
		}
	}
	.actionHolder {
		li {
			a {
				font-size: 0;
				svg {
					font-size: 18px;
					@media (min-width: 1081px) {
						margin-left: 0;
					}
				}
			}
		}
	}
}
.dropdownMenuHolder {
	display: none;
	@media (min-width: 1081px) {
		display: block;
	}
	> div {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding: 12px 24px;
		width: 100%;
		max-height: calc(100vh - 236px);
		overflow: auto;
		> li {
			width: 25%;
			display: block;
			min-width: 0;
			> ul {
				min-width: 0;
				> li {
					display: inline-block;
					margin-right: 18px !important;
					margin-bottom: 6px !important;
					a {
						padding: 0;
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.extraMenu {
	display: flex;
	@media (min-width: 1081px) {
		display: none !important;
	}
	@media (max-width: 767px) {
		display: none;
	}
	ul {
		@media (max-width: 1080px) {
			display: flex;
		}
	}
}
.searchHolder {
	position: relative;
	display: flex;
	align-items: center;
	margin-left: 24px;
	flex-grow: 1;
	> div:first-of-type {
		position: relative;
		width: 100%;
	}
	input {
		border: 1px solid $dark;
		border-radius: $radius;
	}
	.clearBtn {
		cursor: pointer;
		right: 50px;
		position: absolute;
		color: $dark;
		height: 100%;
		padding: 0 12px;
		top: 0;
		display: flex;
		align-items: center;
		display: none;
	}
	button {
		position: absolute;
		right: 0;
		color: $dark;
		height: 46px;
		border-radius: 50%;
		width: 46px;
		text-align: center;
	}
	input:not(:placeholder-shown) ~ button {
		background-color: $cyan;
		color: #fff;
	}
	input:not(:placeholder-shown) ~ .clearBtn {
		display: flex;
	}
}
.searchResultsHolder {
	display: none;
	position: fixed;
	top: 60px;
	bottom: 0;
	background-color: #fff;
	left: 0;
	right: auto;
	width: 100%;
    height: -webkit-fill-available;
    max-height: calc(100vh - 383px);
	border-bottom-left-radius: 32px;
	border-bottom-right-radius: 32px;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		height: 70px;
		left: 0;
		right: 0;
		background: rgb(255,255,255);
		background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
	}
	@media (min-width: 768px) {
		height: auto;
		max-height: calc(100vh - 120px);
		position: absolute;
		top: 100%;
		bottom: auto;
		width: auto;
	}
	&.open {
		display: flex;
	}
}
.searchResultsSelector {
	position: absolute;
	top: 0; left: 0; right: 0;
	display: flex;
	border-top: 1px solid $border;
	@media (min-width: 768px) {
		display: none;
	}
	span {
		display: flex;
		text-align: center;
		width: 50%;
		height: 50px;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		cursor: pointer;
		background-color: $back;
		border-bottom: 1px solid $border;
		&:last-of-type {
			border-left: 1px solid $border;
		}
		&.active {
			color: $cyan;
			background-color: #fff;
			border-bottom-color: #fff;
		}
	}
}
.searchResults {
	min-width: 320px;
	width: 100%;
	padding: 50px 12px 12px 12px;
	overflow: auto;
	@media (min-width: 768px) {
		padding-top: 12px;
	}
	&_products {
		border-left: 1px solid $border;
	}
	.searchResultsTitle {
		display: none;
		@media (min-width: 768px) {
			display: block;
		}
		&.active {
			background-color: $cyan;
		}
	}
	div {
		> a {
		    position: sticky;
		    bottom: 0px;
		}
		> div p a {
			text-decoration: underline;
			color: $blue;
		}
		ul {
			display: block;
			padding: 0 !important;
			list-style: none !important;
			li {
				margin: 0;
				a {
					display: flex;
					padding: 0;
					margin-bottom: 12px;
					> span {
						border-radius: $radiusS;
					}
					> div {
						display: inline-block;
						width: calc(100% - 60px);
						padding-left: 12px;
						white-space: normal;
						div {
						    line-height: 1.4;
						    font-size: 16px;
						}
					}
					p {
						margin: 0;
						font-size: 13px;
					}
					&:hover {
						cursor: pointer;
						> div > div{
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
	.searchResultsImage {
		width: 100%;
		max-width: 60px;
		height: 75px;
		display: inline-block;
		padding: 0;
		border-radius: 8px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}

	}
}
.menuTrigger {
	width: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	display: none;
	cursor: pointer;
	@media (max-width: 1080px) {
		display: flex;
	}
	span {
		height: 2px;
		background-color: $dark;
		margin-left: auto;
		margin-right: 0;
		display: block;
		width: 100%;
		max-width: 24px;
		position: relative;
		transform: rotate(45deg);
		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background-color: $dark;
		}
		&:before {
			top: 0px;
		}
		&:after {
			bottom: 0px;
			transform: rotate(90deg);
		}
	}
	&.closed {
		span {
			transform: none;
			&:before {
				top: -8px;
				transform: none;
			}
			&:after {
				bottom: -8px;
				transform: none;
			}
		}
	}
}
.menuRegisterBtn {
	text-decoration: none !important;
	margin-top: 24px !important;
	border-width: 2px !important;
	border-radius: 36px;
	font-size: 16px !important;
	@media (max-width: 1080px) {
		font-size: 18px !important;
		padding: 14px 24px !important;
		min-width: 275px;
	}
	@media (min-width: 1081px) {
		margin-top: 0px !important;
	}
}
.myProfileBtn {
	background-color: #fff !important;
	margin-top: 0 !important;
	text-decoration: none !important;
	margin-top: 24px !important;
	border: 2px solid $blue;
	font-size: 2px !important;
	border-radius: 36px !important;
	font-size: 18px !important;
	text-align: center;
	min-width: 275px;
	&:before {
		display: none;
	}
	@media (min-width: 1081px) {
		font-size: 0px !important;
		margin-top: 0px !important;
		border-width: 2px !important;
		min-width: 0;
		border: 0;
	}
}
.cartBtn {
	font-size: 22px !important;
	@media (min-width: 1081px) {
		font-size: 0 !important;
		border-left: 1px solid $border;
		padding-left: 24px !important;
	}
	@media (max-width: 1080px) and (max-height: 768px) {
		font-size: 18px !important;
	}
}
.menusBtn {
	font-size: 22px !important;
	@media (min-width: 1081px) {
		font-size: 0 !important;
	}
	@media (max-width: 1080px) and (max-height: 768px) {
		font-size: 18px !important;
	}
}
.menuLoginBtn {
	display: block;
	font-size: 16px !important;
	@media (max-width: 1080px) {
		padding: 14px 24px !important;
		line-height: 1;
		margin-top: 24px !important;
		border: 2px solid $cyan;
		color: $cyan !important;
		border-radius: 36px;
		width: 100%;
		text-align: center;
		font-size: 18px !important;
	}
}
.menuLocaleSelectorLi {
	position: relative;
	> div {
		
		@media (max-width: 1080px) {
			width: 100%;
			text-align: center;
		}
	}
}
.localeItem {
	cursor: pointer;
	img {
		width: 20px;
		min-width: 20px;
	}
}
.activeLocale {
	font-size: 0;
	img {
		display: block;
		@media (max-width: 1080px) {
			margin-right: 8px;
		}
	}
	@media (max-width: 1080px) {
		font-size: 16px;
		color: $dark;
		display: flex;
		justify-content: center;
		margin-top: 6px;
		font-weight: 500;
		margin-left: -4px;
		padding: 12px 0;
	}
}
.localeListScroll {
    max-height: calc(100vh - 220px);
    overflow: auto;
}
.localeList {
	position: absolute;
	top: calc(100% - 8px);
	right: -8px;
	background-color: #fff;
	min-width: 208px;
	text-align: left;
	@media (max-width: 1080px) {
		position: fixed;
		top: auto;
		bottom: 0;
		left: 0; right: 0;
		padding: 0px 0 24px 0;
		border-top-right-radius: 24px;
		border-top-left-radius: 24px;
		overflow: hidden;
	}
}
.localeListItem {
	white-space: nowrap;
	color: $dark;
	margin-bottom: 0px;
	padding: 12px 12px;
	@media (max-width: 1080px) {
		margin: 0 auto;
		padding: 12px;
    	max-width: 300px;
		font-weight: 500;
	}
	img {
		vertical-align: text-bottom;
		margin-right: 8px;
	}
}
.localeListHeader {
	background-color: #f9f7f4;
	background-color: #fff;
    padding: 18px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    border-bottom: 1px solid #e6e6e6;
    max-width: 310px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 0;
    @media (min-width: 1081px) {
	    border-bottom: 0;
    	padding: 12px 12px 12px 12px;
    }
    img {
    	width: 14px;
    	display: block;
    	cursor: pointer;
    }
}