@import "vars.scss";
.PolicyHeader {
	ul {
		width: auto;
		margin-left: -12px;
		margin-right: -12px;
		@media (min-width: 1024px) {
		}
		li {
			margin: 0 12px;
			margin-bottom: 12px;
			flex-grow: 1;
			width: calc(50% - 24px);
			@media (min-width: 768px) {
				width: auto;
			}
			@media (min-width: 1024px) {
				margin-bottom: 0;
			}
			a {
				display: inline-block;
				width: 100%;
				text-align: center;
				padding: 12px;
				background-color: #fff;
				box-shadow: $shadow;
				@media (min-width: 1024px) {
					border: 0;
				}
			}
		}
	}
}
.PolicyContent {
	padding-top: 60px;
	ol {
		padding-left: 16px;
		li {
			color: #515960;
		    font-weight: 400;
		    line-height: 1.65;
		    margin-top: 0;
		}
		ol {
			list-style: none;
		}
	}
	.PolicyContentList {
		li {
			font-weight: 600;
		}
	}
	.PolicyCharList {
		list-style: lower-latin;
		list-style-position: inside;
		li {
			&::marker {
				min-width: 20px;
			}
		}
	}
	ul {
		margin-bottom: 24px;
		list-style: inside;
		li {
			color: #515960;
		    font-weight: 400;
		    line-height: 1.65;
		    margin-top: 0;
		}
	}
	table {
		font-size: 13px;
		border-collapse: collapse;
		border: 1px solid $border;
		max-width: calc(100vw - 32px);
		overflow: auto;
		display: block;
		tr {
			border-bottom: 1px solid $border;
			td, th {
				padding: 12px;
			}
			&:nth-of-type(odd) {
				background-color: #fff;
			}
		}
	}
	.Accordion {
		background-color: #fff;
	}
}