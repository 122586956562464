@import "vars.scss";
$sidebar: 200px;

$blue: #223a2a;
$orange: darken(#ecc369, 5%);

.dashboard {
:global(:root) {
	--primary-color: #1755a0;
	--secondary-color: #16212a;
	--third-color: #fc954b;
}
}

$primary: var(--primary-color);
$secondary: var(--secondary-color);
$third: var(--third-color);

// $blue: #16212a;

// $orange: #ecc369;

$baby: #ecc369;
$cyan: #223a2a;



.dashboard {
	display: flex;
	flex-wrap: wrap;
	background-color: $back;
	margin-left: $sidebar;
	@media (max-width: 768px) {
		margin-left: 0;
	}
	table {
		a {
			color: $cyan;
		}
	}
}
.topMenu {
	height: 50px;
	display: flex;
	background-color: #fff;
	justify-content: space-between;
	align-items: center;
	padding: 12px 18px;
	right: 0;
	left: $sidebar;
	box-shadow: $shadow;
	z-index: 1;
	position: fixed;
	@media (max-width: 768px) {
		padding-left: 60px;
		left: 0;
	}
}
.UserMenu {
	position: relative;
	align-items: center;
	border-top: 1px solid rgba(255,255,255,.2);
	padding-top: 18px;
	> a {
		padding: 18px;
		cursor: pointer;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		svg {
			margin-right: 8px;
		}
	}
}
.logoHolder {
	width: auto;
	height: 50px;
    background-color: darken($blue, 3%);
    padding: 16px 12px;
    @media (max-width: 768px) {
    	padding-left: 60px;
    }
	img {
		height: 100%;
		width: auto;
		max-width: 100%;
	}
	&__horeca {
	}
}
.sideMenu {
	position: fixed;
	top: 0; bottom: 0; left: 0;
	color: #fff;
	z-index: 1234;
	@media (max-width: 768px) {
		bottom: auto;
		width: 100%;
	}
	.mainMenu {
		li {
			position: relative;
			&[disabled] {
				pointer-events: none;
				opacity: .3;
			}
			a {
				display: block;
				padding: 18px;
				cursor: pointer;
				&:hover {
					background-color: darken($blue, 2%);
					color: $orange;
					text-decoration: none;
				}
				svg {
					width: 20px;
					margin-right: 8px;
				}
				&.active {
					color: #fff !important;
		            font-weight: normal !important;
		            &:before {
		                display: none !important;
		            }
				}
			}
			> a {
				padding-left: 18px;
			}
		}
		li.active {
			
			> a {
				color: $orange;
			}
			.subMenu {
				display: block;
				position: relative;
    			left: auto;
			}
		}
		li:hover {
			.subMenu {
				display: block;
			}
		}
	}
	.subMenu {
		font-size: 13px;
		display: none;
		position: absolute;
	    top: 0;
	    left: 100%;
	    background-color: lighten($blue, 5%);
	    width: 200px;
	    padding: 12px 0;
	    @media (max-width: 768px) {
			width: 100%;
		}
	    &:after {
	    	right: 100%;
			top: 36px;
			border: solid transparent;
			content: "";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(255, 0, 0, 0);
			border-right-color: lighten($blue, 5%);
			border-width: 8px;
			margin-top: -8px;
	    }
		li {
			a {
				opacity: 0.8;
				padding: 12px 0;
				padding-left: 24px;
				&:hover {
					color: $orange;
					background-color: transparent;
					opacity: 1;
					text-decoration: none;
				}
				&.active {
					color: $orange !important;
		            font-weight: bolder !important;
		            opacity: 1;
				}
			}
		}
	}
}
.menuHolder {
	width: $sidebar;
	background-color: $blue;
	padding: 0px;
	box-shadow: $shadow;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 50px);
    overflow: auto;
	&.closed {
		@media (max-width: 768px) {
			display: none;
		}
	}
	@media (max-width: 768px) {
		width: 100%;
		max-height: calc(100vh - 50px);
	}
}
.menuTrigger {
	height: 50px;
	width: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	position: fixed;
	top: 0;
	left: 220px;
	display: none;
	@media (max-width: 768px) {
		left: 0px;
		display: flex;
	}
	span {
		height: 2px;
		background-color: #fff;
		margin: 0 auto;
		display: block;
		width: 100%;
		max-width: 24px;
		position: relative;
		transform: rotate(45deg);
		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background-color: #fff;
		}
		&:before {
			top: 0px;
		}
		&:after {
			bottom: 0px;
			transform: rotate(90deg);
		}
	}
	&.closed {
		span {
			transform: none;
			&:before {
				top: -8px;
				transform: none;
			}
			&:after {
				bottom: -8px;
				transform: none;
			}
		}
	}
}

.AdminContent {
	min-height: 100vh;
	max-height: 100vh;
	overflow: auto;
	flex-grow: 1;
	padding: 24px;
	@media (max-width: 768px) {
		max-height: none;
		padding: 8px;
		margin-top: 50px;
	}
	.contentInner {
		background-color: #fff;
		box-shadow: $shadow;
		padding: 24px;
		padding-bottom: 50px;
		margin-bottom: 24px;
		@media (max-width: 768px) {
			padding-left: 18px;
			padding-right: 18px;
		}
	}
	.contentHeader {
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid $border;
		h1 {
			margin: 0;
			padding: 0;
    		font-weight: bolder;
    		font-size: 24px;
		}
	}
	hr {
		margin-bottom: 32px;
	}

	input:not([type=checkbox]):not([type=radio]):not([type=submit]), textarea {
		border-radius: 4px;
	}
	article {
		margin-left: -24px;
		margin-right: -24px;
		margin-top: -73px;
		@media (max-width: 768px) {
			margin-left: -18px;
			margin-right: -18px;
		}
		div[data-rttabs="true"] {
			> div {
				padding: 0 18px;
				@media (min-width: 768px) {
					padding: 0 36px;
				}
			}
		}
	}
	button[favbtn="true"] {
		display: none;
	}
}
.horecaBreadcrumbs {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 14px;
	> b {
		margin-right: 12px;
	}
	> div {
		align-items: center;
		row-gap: 12px;
	}
	> div > a {
		// margin-left: 12px;
	}
	span {
		margin-right: 16px;
		a {
			position: relative;
			display: block;
			border-radius: 4px;
			background-color: darken($baby, 16%);
			padding: 2px 10px;
			border: 1px solid darken($baby, 16%);
			font-size: 14px;
			font-weight: 600;
			svg {
				margin-right: 8px;
				font-size: 14px;
			}
			&:after {
				content: ">";
				position: absolute;
				left: calc(100% + 4px);
			}
		}
		&:nth-of-type(2) a {
			background-color: darken($baby, 8%);
			border: 1px solid darken($baby, 12%);

		}
		&:nth-of-type(3) a {
			background-color: darken($baby, 0%);
			border: 1px solid darken($baby, 5%);
		}
		&:last-of-type {
			a:after {
				content: "";
			}
		}
		&:hover a {
			background-color: $baby;
			text-decoration: none;
		}
	}
}
.horecaBreadcrumbs {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 14px;
	> b {
		margin-right: 12px;
	}
	> div {
		align-items: center;
		row-gap: 12px;
	}
	> div > a {
		// margin-left: 12px;
	}
	span {
		margin-right: 16px;
		a {
			position: relative;
			display: block;
			border-radius: 4px;
			background-color: darken($baby, 16%);
			padding: 2px 10px;
			border: 1px solid darken($baby, 16%);
			font-size: 14px;
			font-weight: 600;
			svg {
				margin-right: 8px;
				font-size: 14px;
			}
			&:after {
				content: ">";
				position: absolute;
				left: calc(100% + 4px);
			}
		}
		&:nth-of-type(2) a {
			background-color: darken($baby, 8%);
			border: 1px solid darken($baby, 12%);

		}
		&:nth-of-type(3) a {
			background-color: darken($baby, 0%);
			border: 1px solid darken($baby, 5%);
		}
		&:last-of-type {
			a:after {
				content: "";
			}
		}
		&:hover a {
			background-color: $baby;
			text-decoration: none;
		}
	}
}
.horecaBarSelector {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 14px;
	justify-content: space-between;
	b {
		margin-right: 12px;
	}
	> div {
		align-items: center;
		row-gap: 12px;
		display: flex;
	}
	> div > a {
		// margin-left: 12px;
	}
	span {
		margin-right: 16px;
		a {
			position: relative;
			display: block;
			border-radius: 4px;
			color: $green;
			padding: 2px 10px;
			border: 1px solid darken($baby, 16%);
			border-color: $green;
			font-size: 14px;
			font-weight: 600;
			svg {
				margin-right: 8px;
				font-size: 14px;
			}
		}
		&:hover a {
			background-color: $baby;
			text-decoration: none;
		}
	}
	.horecaBarSelectorSelected {
		span a {
			background-color: darken($baby, 16%);
			color: #000;
			border-color: darken($baby, 16%);
		}
	}
}

.horecaOrgList {
	li {
		a {
			display: block;
			border: 1px solid $border;
			margin-top: 8px;
			padding: 12px;
			font-weight: 500;
			color: $blue;
			background-color: #f9f9f9;
			&:hover {
				background-color: $baby;
				text-decoration: none;
			}
		}
	}
}
.AdminSection {
	padding-bottom: 24px;
	border-bottom: 1px solid $border;
	margin-bottom: 24px;
	.sectionHeader {
		h2 {

		}
		p {

		}
	}
	&:last-of-type {
		border-bottom: 0;
	}
	.inputContainer {

	}
	input:not([type=checkbox]):not([type=radio]):not([type=submit]), textarea {
		border-radius: 4px;
	}
}
.sectionFooter {
    position: fixed;
    bottom: 0;
    left: 200px;
    right: 0;
    z-index: 1;
    background-color: #fff;
    padding: 8px 24px;
    align-items: center;
    border-top: 1px solid #e6e6e6;
}
.checkContainer {
	> div {
		flex-direction: row;
		input {
			border-radius: 4px !important;
			margin-right: 12px;
		}
	}
}
.Search {
	input {
		width: 100%;
		padding: 6px;
	}
}

.formFooter {

	input {

	}
}

.List {
	&Header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $border;
		> * {
			padding-left: 12px;
			padding-right: 12px;
		}
		h4 {
			flex-grow: 1;
		}
	}
	&--item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $border;
		> * {
			padding-left: 12px;
			padding-right: 12px;
		}
		.checkBox {

		}
		img {
			height: 50px;
			width: auto;
		}
		h4 {
			flex-grow: 1;
		}
	}
}

.button {
	text-align: center;
	background-color: $orange;
	color: #fff;
	padding: 12px 20px;
	margin-bottom: 24px;
	display: block;
	border-radius: 4px;
	font-size: 16px;
	line-height: 1.65;
	font-weight: 500;
	&.Cyan {
		background-color: $cyan;
	}
	&.Large {
		padding: 20px;
	} 
}
.RecipeTabHeader {
	margin-bottom: 24px;
	border: 1px solid $border;
	padding: 12px;
	padding-bottom: 0;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		display: block;
		top: 0; right: 0; bottom: 0;
		width: 120px;
		pointer-events: none;
		background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	}
	ul {
		display: flex;
		width: 100%;
		overflow: auto;
		padding-bottom: 12px;
		padding-right: 60px;
		li {
			padding: 8px 12px;
			background-color: $back;
			border: 1px solid $border;
			cursor: pointer;
			margin-right: 8px;
			text-align: center;
			flex-grow: 1;
			white-space: nowrap;
			&:last-of-type {
				margin-right: 0;
			}
			&[aria-selected="true"] {
				background-color: $cyan;
				color: #fff;
				border-bottom: 0;
				border-color: $cyan;
			}
		}
		.NewRecipeBtn {
			border: 1px solid $cyan;
			color: $cyan;
			justify-self: flex-end;
			background-color: transparent;
			margin-left: 8px;
		}
	}
}
.AdminSetting {
	display: flex;
	flex-wrap: wrap;
	&.AdminSetting3 {
		> div {
			width: calc(100% / 3);
			padding-right: 16px;
			&:nth-of-type(3n) {
				padding-right: 0;
			}
			@media (max-width: 768px) {
				width: 100%;
				padding-right: 0;
			}
		}
	}
}
.AdminTabs {
	margin-bottom: 24px;
}
.AdminTabHeader {
	ul {
		display: flex;
		border-top: 1px solid $border;
		border-left: 1px solid $border;
		li {
			border-right: 1px solid $border;
			padding: 8px 12px;
			flex: 1;
			background-color: $back;
			border-bottom: 1px solid $border;
			cursor: pointer;
			&[aria-selected="true"] {
				background-color: #fff;
				border-bottom: 0;
				font-weight: 600;
				color: $cyan;
			}
		}
	}
}
.AdminTabContent {
	padding: 12px;
	border: 1px solid $border;
	border-top: 0;
}
.AdminHeaderTabHeader {
	display: flex;
	gap: 24px;
	.AdminHeaderTabButton {
		text-align: center;
		flex-grow: 1;
		border: 1px solid $cyan;
		&.active {

		}
	}
}
.horecaMenuCocktails {
	border-left: 1px solid #e6e6e6;
	border-top: 1px solid #e6e6e6;
}
.horecaMenuCocktail {
	border-bottom: 1px solid #e6e6e6;
	border-right: 1px solid #e6e6e6;
	> a {
		border-radius: 5px !important;
		> div {

		}
		header {
			p {
				font-size: 11px;
				font-weight: 500;
			}
			h4 {
				font-size: 16px;
			}
			span {

			}
		}
	}
	> div[horecaInfo="true"] {
		p {
			font-size: 13px;
			font-weight: 500;
			margin-bottom: 6px;
		}
	}

}
.horecaMenuIngredients {
	border: 1px solid #e6e6e6;
    li {
    	border-bottom: 1px solid #e6e6e6;
    	&:nth-of-type(even) {
    		background-color: $back;
    	}
    	> div {
    		padding-top: 12px;
    		padding-bottom: 12px;
    		margin-bottom: 0;
    	}
    	label {
    		font-weight: 500;
    		span:not(:empty) {
    			margin-right: 4px;
    		}
    		span[measurementPrice="true"] {
    			float: right;
    			margin-right: 12px;
    		}
    	}
    }
}
.CocktailInfoTop {
	display: flex;
	> div {
		&:first-of-type {
			flex-grow: 1;
			margin-right: 24px;
		}
	}
}
.Grid {
	border-top: 1px solid $border;
	border-right: 1px solid $border;
	.GridRow {
		grid-template-columns: repeat(1, 1fr);
	}
	&.GridWithHandle .GridRow {
		grid-template-columns: 30px 1fr;
	}
	&.Grid2 {
		.GridRow {
			grid-template-columns: repeat(2, 1fr);
		}
		&.GridWithHandle .GridRow {
			grid-template-columns: 30px 1fr 1fr;
		}
	}
	&.Grid4 {
		.GridRow {
			grid-template-columns: repeat(4, 1fr);
		}
	}
	&.Grid6 {
		.GridRow {
			grid-template-columns: repeat(6, 1fr);
		}
		&.GridWithHandle .GridRow {
			grid-template-columns: 30px 1fr 1fr 1fr 1fr 1fr 1fr;
		}
	}
	&.GridIngredients {
		.GridRow {
			grid-template-columns: repeat(6, 1fr);
		}
		&.GridWithHandle .GridRow {
			grid-template-columns: 30px 2.5fr 90px 150px 90px 150px 1fr 0.5fr;
		}
	}
	&.GridWithHandleBasic {
		
		.GridRow {
			grid-template-columns: auto;
			> .GridItemHandle {
				visibility: hidden;
				display: none;
			}
				.GridIngredients {
					.GridRow {
						grid-template-columns: 30px 2.5fr 90px 150px 90px 150px 1fr 0.5fr;
						> .GridItemHandle {
							visibility: visible;
							display: block;
						}
					}
				}
		}
	}
}
.GridHeader {
	background-color: $back;
}
.GridBody {

}
.GridRow {
	position: relative;
	display: grid;
    grid-template-columns: repeat(1, 1fr);
    border-bottom: 1px solid $border;
    > div {
    	padding: 24px 12px;
    	display: flex;
    	align-items: center;
    	border-left: 1px solid $border;
    	font-size: 13px;
    	&.GridItemClosed {
    		max-height: 115px;
    		overflow: hidden;
    		&:after {
    			content: '';
    			position: absolute;
    			height: 50%;
    			bottom: 0; left: 30px; right: 0;
    			pointer-events: none;
    			background: rgb(255,255,255);
				background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(250,250,250,1) 100%);
    		}
    	}
    	> div {
    		width: 100%;
    		margin: 0;
    	}
    }
}
.TimelineRowBody {
	background-color: #fff;
}
.GridSplit {
	display: flex;
	> div {
		flex-grow: 1;
		padding-right: 16px;
		&:last-of-type {
			padding-right: 0;
		}
	}
}

.GridItemHandle {
	position: relative;
	justify-content: center;
	> div {
		display: none;
		position: absolute;
		background-color: $baby;
	    top: 0;
	    bottom: 0;
	    right: 0;
	    left: 0;
	    width: auto;
	    display: none;
	    justify-content: space-evenly;
	    align-items: center;
	    flex-direction: column;
	}
	span, a {
		display: block;
		cursor: pointer;
		img {
		    width: 15px;
		    min-width: 15px;
		    display: block;
		}
	}
	.GridIndex {
		z-index: 1;
		font-weight: 600;
		pointer-events: none;
	}
	.GridDeleteBtn {
		cursor: pointer;
		display: flex;
	    width: 100%;
	    text-align: center;
	    cursor: pointer;
	    height: 30px;
	    justify-content: center;
	    align-items: center;
	    &:hover {
	    	background-color: lighten($warning, 50%);
	    }
	    svg, img {
	    	fill: white;
	    }
	}
	.GridMoveBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: move;
		width: 100%;
		height: 30px;
		&:hover {
	    	background-color: darken($baby, 7.5%);
	    }
	}
}
.GridRow {
	&:hover, &:active {
		> .GridItemHandle > div {
			display: flex;
		}
	}
}
.TimelineRow {
	border-bottom: 1px solid $border;
	border-top: 24px solid $border;
}
.hideStepBtn {
	position: absolute;
	top: 12px;
	top: -26px;
	right: 12px;
	// background-color: #fff;
	color: $blue;
	border-radius: 4px;
	width: auto !important;
	padding: 4px 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 123;
	font-weight: 500;
	font-size: 12px;
	&.hideStepBtnClosed {
		// background-color: $baby;
	}
	svg {
		margin-left: 6px;
	}
	&:hover {
		// background-color: $baby;
	}
}
.equipmentSelect {
	
}
.Button {
	border-radius: 4px;
}
.cProductList {

}
.cProductItem {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border;
    margin-bottom: 12px;
    padding-bottom: 12px;
}
.cProductImg {
    img {
        width: 100%;
        max-width: 60px;
        height: auto;
    }
}
.cProductInfo {
    flex-grow: 1;
    padding-left: 18px;
    padding-right: 18px;
    h3 {
        margin-bottom: 6px;
    }
    p {
        margin-bottom: 0;
        font-size: 13px;
        span {
            font-weight: bold;
        }
        button {
            img {
                display: inline-block;
                height: 14px;
                width: 14px;
            }
        }
    }
}
.cPrice {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    span {
        font-size: 11px;
        font-weight: normal;
    }
}

.cCouponHolder {
    display: block;
    white-space: nowrap;
    a {
        display: block;
        font-size: 14px;
    }
}
.cCouponInput {
    width: 100%;
    display: none;
    margin-top: 8px;
    &.active {
        display: flex;
    }
    input {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    button {
        background-color: #000;
        color: #fff;
        padding: 0 18px;
        font-size: 13px;
        border-bottom-right-radius: $radius;
        border-top-right-radius: $radius;
    }
}
.cLineItem {
    display: flex;
    justify-content: space-between;
    p {
        font-size: 14px;
        margin-bottom: 0;
    }
}
.cLineItemTotal {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    p {
        color: #000;
        font-size: 18px;
    }
}
.cPolicyLinks {
    display: flex;
    font-size: 14px;
    border-top: 1px solid #ddd;
    justify-content: space-between;
    a {
        padding: 8px 4px;
    }
}
.cIcon {
    font-size: 50px;
}

.overviewGridContainer {
	// border-collapse: collapse;
	thead {
		position: sticky;
	    top: -24px;
	    background-color: #fff;
	    border-bottom: 1px solid #ddd;
	    z-index: 1;
		tr th {
			white-space: nowrap;
		}
	}
	tbody {
		tr {

			&.selected {
				background-color: $baby !important;
			}
		}
	}
	thead, tbody {
		tr {

			&:nth-of-type(even) {
				background-color: #f9f9f9;
			}
			th, td {
				padding: 8px 8px;
				&:first-of-type {
					padding-left: 0;
				}
				&:last-of-type {
					padding-right: 0;
				}
			}
			th, td {
				
				&.image {
					
				}
				&.name {
					
				}
				&.cocktails {
					
				}
				&.actions {
					text-align: right;
					width: 60px;
					max-width: 250px;
					> div {
						display: flex;
						justify-content: end;
					}
					a, button {
						margin-right: 8px;
						width: 30px;
						min-width: 30px;
						height: 30px;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: $baby;
						border-radius: 4px;
						&.tableActionButton {
							min-width: unset;
							width: auto;
							white-space: nowrap;
							padding-left: 6px;
							padding-right: 6px;
							font-size: 12px;
							font-weight: 600;
							&__blue {
								background-color: $blue;
								color: #fff;
							}
							&__cyan {
								background-color: $cyan;
								color: #fff;
							}
							&__orange {
								background-color: $orange;
								color: #fff;
							}
							&__cyanBorder {
								border: 1px solid $cyan;
								color: $cyan;
							}
							&.isAdded {
								pointer-events: none;
							    background-color: #dae8d2;
    							color: #064c35;
							}
						}
						&:hover {
							background-color: darken($baby, 5%);
						}
						&.plusBtn {
							background-color: $cyan;
							color: #fff;
							text-decoration: none;
							&:hover {
								background-color: lighten($cyan, 5%);
							}
						}
						&.trashCan {
							color: $warning;
							background-color: $pink;
							&:hover {
								background-color: darken($pink, 5%);
							}
						}
						&:last-of-type {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}
.doubleTable {
	.doubleTableItem {
		&:first-of-type {
			border-right: 1px solid #ddd;
			padding-right: 12px;
		}
		&:last-of-type {
			padding-left: 12px;
		}
		table {
			thead {
				display: block;
				tr {
					display: table;
					table-layout: fixed;
					width: 100%;
				}
			}
			tbody {
				max-height: 100vh;
				overflow: auto;
				display: block;
				tr {
					display: table;
					table-layout: fixed;
					width: 100%;
				}
			}
			tr td, tr th {
				overflow: hidden;
  				text-overflow: ellipsis;
				&.image, &.check {
					max-width: 40px;
					width: 100px;
				}
				&.brand {
					width: 20%;
				}
				&.actions {
					width: 150px;
					max-width: 116px;
				}
			}
		}
		input[type="checkbox"] {
			border-radius: 4px;
		}
	}
}
.Pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
	div {
	}
	button {
		border-radius: 0;
		border: 1px solid #ddd;
		&:first-of-type {
			border-right: 0;
		}
		&:last-of-type {
			border-left: 0;
		}
		&:hover {
			
			background-color: $baby;
		}
		&[disabled] {
			opacity: .3;
			background-color: #e6e6e6 !important;
		}
	}
	span {
		margin-left: 12px;
		margin-right: 12px;
	}
	select {
		font-size: 16px;
		padding: 8px 8px;
		border: 1px solid #ddd;
		cursor: pointer;
	}
}
.localeSelector {
	display: flex;
	width: 100%;
	margin-bottom: 24px;
	border: 1px solid $border;
	margin-bottom: 50px;

	> div {
		flex-grow: 1;
		border: 2px solid transparent;
		padding: 12px;
		cursor: pointer;
		background-color: $back;
		border-bottom: 2px solid transparent;
		border-right: 1px solid $border;
		&:last-of-type {
			border-right: 0;
		}
		&.active {
			background-color: #fff;
			border-bottom-color: $cyan;
			font-weight: bold;
			color: $cyan;
		}
	}
}

.UserInviteModal {

}
.InviteUserList {
	.InviteUserLabels {
		display: flex;
		label {
			padding-left: 6px;
			width: calc(100% / 3);
			&:first-of-type {
				padding-left: 12px;
			}
		}
	}
	ul {
		overflow: visible !important;
	}
}
.UserInviteInputs {
	.InviteUserEmailInput {
		
		input {
			border-radius: 4px !important;
		}
	}
	li {
		gap: 12px;
		> div {
			width: calc(100% / 3);
		}
	}
}
.UserInviteSelect {
	min-width: 120px;
	> div {
		border-color: $border;
		min-height: 46px;
	}
}

.checklistInputHolder {
	display: flex;
	gap: 12px;
	flex-wrap: wrap;
	width: 100%;
	.checklistInputItem {
		width: 100%;
		@media (max-width: 768px) {
			width: 100%;
		}
		> div {
			margin-bottom: 0;
		}
		input {
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
		textarea {
			resize: vertical;
			min-height: 330px;
			border-top-left-radius: 0 !important;
			border-top-right-radius: 0 !important;
			border-top: 0 !important;
			line-height: 1.42;
		}
		input[disabled] {
			background-color: $back;
			font-weight: 600;
			color: #000;
		}
		textarea[disabled] {
			background-color: #fff;
			color: #000;
		}
		.mb0 {
			margin-bottom: 0 !important;
		}
	}
	ol {
		list-style: none;
		border: 1px solid $border;
		border-bottom: 0;
		border-top: 0;
		li {
			display: flex;
			border-bottom: 1px solid $border;
			padding: 12px;
			padding-left: 16px;
			align-items: center;
			input[type="checkbox"] {
				border-radius: 0;
			    transform: scale(1.5);
			    margin-right: 16px;
			    transform-origin: center center;
			}
			a {
				text-decoration: underline;
			}
		}
	}
}
.listEditor {
	background-color: #fff;
}
.draggableColumn {
	padding: 6px;
	background-color: $back;
	border: 1px solid $border;
	width: 100%;
}
.draggableColumnHeader {
	display: flex;
	align-items: end;
	> div {
		flex-grow: 1;
		margin-right: 6px;
	}
	.dragBtn {
		background-color: transparent;
		border-right: 0;
		height: 46px;
		width: 46px;
		background-color: #fff;
		padding: 14px;
		margin-right: 6px;
		border: 1px solid $border;
		margin-bottom: 24px;
		img {

		}
	}
	.trashBtn {
		height: 46px;
		width: 46px;
		background-color: #fff;
		padding: 14px;
		border: 1px solid $border;
		margin-bottom: 24px;
		cursor: pointer;
	}
}
.trashBtn {
	width: 20px;
}
.draggableRow {
	display: flex;
	border: 1px solid $border;
}
.dragBtn {
	width: 28px;
	background-color: #fff;
	border-right: 1px solid $border;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px;
	&:hover {
		background-color: lighten($baby, 20%);
	}
}
.trashBtn {
	width: 28px;
	background-color: #fff;
	border-right: 1px solid $border;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px;
	cursor: pointer;
	&:hover {
		background-color: lighten($warning, 50%);
	}
}
.barPageSection {
	background-color: transparent;
	.barPageSectionHeader {
		display: flex;
		justify-content: space-between;
		@media (max-width: 767px) {
			margin-bottom: 12px;
		}
	}
	.barScroll {
		background-color: transparent;
		@media (min-width: 769px) {
			width: calc(100vw - 200px);
		    margin-left: calc((100% - (100vw - 200px)) / 2);
		    padding-left: calc(((100vw - 200px) - 100%) / 2 - 12px);
		    padding-right: calc(((100vw - 200px) - 100%) / 2 - 12px);
		}
		.barScrollItem {
			background-color: transparent;
			> a {
				text-decoration: none;
				> div {
					background-color: #f0f0f0;
					border-radius: 5px;
					overflow: hidden;
				}
				.barScrollContentChecklist {
					background-color: $gold;
					aspect-ratio: 4 / 5;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 12px !important;
					text-align: center;
					color: #fff;
					text-decoration: none !important;
					img {
						width: 70%;
						margin-bottom: 16px;
						max-width: 120px;
						max-height: 50px;
					}
					h4 {
						color: #fff;
						word-break: break-word;
						text-align: center;
						text-decoration: none !important;
						margin-bottom: 0;
						font-weight: 500;
						margin-bottom: 12px;
						line-height: 1.42;
					}
					> span {
						font-size: 24px;
						font-weight: 400;
					}
					&.finished, &.complete {
						background-color: $success;
					}
					&.failed, &.uncomplete {
						background-color: $warning;
					}
				}
				header {
					padding-top: 8px;
					h4 {
						color: #000;
						word-break: break-word;
					}
				}
				&:hover {
					.barScrollContentChecklist {
						h4 {
							text-decoration: none !important;
						}
					}
					header h4 {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.QuickCocktailSearch {
	position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    display: none;
    @media (max-width: 768px) {
    	display: block;
	    top: 0;
	    bottom: auto;
	    right: 0;
    }
    .QuickCocktailSearchBtn {
    	background-color: $green;
	    color: #fff;
	    border-radius: 4px;
	    height: 50px;
	    width: 50px;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    transition: all .3s ease-out;
	    z-index: 1;
	    cursor: pointer;
    }
    .QuickCocktailSearchInput {
    	border-radius: 4px;
    	position: absolute;
    	right: 100%;
    	transition: all .3s ease-out;
    	margin-right: 12px;
    	opacity: 1;
    	height: 50px;
    	display: flex;
    	align-items: center;
    	padding-right: 50px;
    	margin-right: -50px;
    	opacity: 0;
    	@media (max-width: 768px) {
    		display: none;
    	}
    }
    label {
    	margin-bottom: 0;
    	display: block;
    	white-space: nowrap;
    	display: none;
    }
    input {
    	display: block;
    	width: 180px !important;
    	font-size: 16px !important;
    	border-radius: 4px !important;
    	height: 50px !important;
    	background-color: #fff;
    	margin-right: -4px;
    }
    &:hover {
    	.QuickCocktailSearchInput {
    		opacity: 1;
    	}
    }
}