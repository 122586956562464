@import "vars.scss";
.Modal {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ModalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.ModalContent {
  max-width: 480px;
  min-width: 250px;
  padding: 0px;
  background-color: #fff;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
  max-height: -webkit-fill-available;
  max-height: calc(100vh - 160px);
  @media (min-width: 768px) {
    min-width: 600px;
  }
  &.fullWidth {
    width: 90vw;
    max-width: none;
  }
}
.ModalHeader {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  background-color: $back;
  font-weight: bolder;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  > span {
    display: block;
    margin-left: 12px;
    cursor: pointer;
  }
  img {
    display: block;
    height: 24px;
    width: 24px;
  }
}
.ModalBody {
  padding: 24px;
  overflow: auto;
  max-height: calc(100vh - 235px);
  overflow: auto;
  height: 100%;
}
.ModalBodyContent {

}
.ModalFooterSticky {
  position: sticky;
  bottom: -24px;
  background-color: #f9f9f9;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  padding: 12px 24px;
}
.LoginModalBody {
  > a {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 123;
    background-color: $back;
    > img {
      display: block;
      height: 20px;
      width: 20px;
    }
  }
}
.LoginModalContainer {
  width: 100%;
}
.LoginModalHeader {
  position: relative;
  overflow: hidden;
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
  padding-right: 60px;
  > ul {
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    background-color: $back;
    font-weight: bolder;
    justify-content: space-between;
    align-items: center;
    position: relative;
      > li {
        padding: 24px;
        display: flex;
        align-items: center;
        width: 50%;
        white-space: nowrap;
        justify-content: center;
        cursor: pointer;
        max-height: 60px;
        &[aria-selected="true"] {
          background-color: #fff;
        }
        > img {
          height: 18px;
          width: 18px;
          margin-right: 8px;
        }
    }
    }
}
.LoginModalContent {
  padding: 24px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  @media (min-device-width: 250px) and (orientation: portrait) {
    max-height: calc(100vh - 220px);
  }
  > img {
    display: block;
    width: 50px;
    height: 50px;
    margin: 0 auto 18px auto;
  }
}
.LegalHeading {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid $border;
}
.LegalButtonGroup {
  display: flex;
  justify-content: center;
  > a, button {
    border: 1px solid #000;
    border-radius: 0;
    padding: 18px 12px;
    text-transform: uppercase;
    flex-grow: 1;
    font-weight: bold;
    font-size: 18px;
    width: 50%;
    text-decoration: none;
  }
  > button {
    background-color: #000;
    color: #fff;
  }
}
.InputContainer {
  margin-bottom: 12px;
}
.starsInput > div > span {
  cursor: pointer;
  min-width: 30px;
  width: 30px;
  height: 30px;
  fill: $blue;
}
.registerModalbtn {
  
  > input {

  }
}
.mobileBottom {
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.shareActions {
  margin-bottom: 12px;
  a {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border;
    border-radius: 50%;
    svg {
      height: 20px;
      width: 20px;
    }
  }
}
.menuList {
  ul {
    border: 1px solid $border;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 24px;
    li {
      display: flex;
      border-top: 1px solid $border;
      padding: 12px;
      justify-content: space-between;
      align-items: center;
      &:first-of-type {
        border: 0;
      }
      &:nth-of-type(even) {
        background-color: $back;
      }
      span {
        font-size: 14px;
        flex-grow: 1;
      }
      button {
        font-size: 11px;
        background-color: $cyan;
        border-color: $cyan;
        color: #fff;
        border-radius: 25px;
        padding: 8px 12px;
        font-weight: 600;
        margin-left: 12px;
        &:hover {
          background-color: lighten($cyan, 10%);
        }
        &.Remove {
          background-color: $warning;
          &:hover {
            background-color: lighten($warning, 5%);
          }
        }
        &.Added, &.Success {
          pointer-events: none;
          background-color: $success;
        }
      }
    }
  }
}