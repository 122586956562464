@import "vars.scss";
.homeBanner {
    // background-image: url(/img/banner3.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: auto;
    position: relative;
    &:before {
        content: '';
        // background-image: url(/img/pattern2.png);
        background-color: rgba(0,0,0,.3);
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        display: block;
        background-size: 36px;
        opacity: .9;
    }
    &:after {
        

    }
}
.downloadBanner {
    background-image: url(/img/download.jpg);
    height: 80vh;
    @media (min-width: 767px) {
        height: calc(100vh - 80px);
    }
    &:after {
        display: none;
    }
}
.downloadLogo {
    background-image: url(/img/cc_logo_full-white.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 150px;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
}
.downloadDisplay {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 12px;
    padding-top: 48px;
    background-image: linear-gradient(180deg, transparent, $blue);
}
.homeContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}
.videoDisplay { 
    display: block;
    max-height: 650px;
    overflow: hidden;
    > div {
        @media (min-width: 1155px) {
            margin-top: calc(650px / 2);
            transform: translateY(-50%);
        }
    }
    @media (min-width: 650px) {
        display: block;
    }
}
.videoDisplayMob {
    display: block;
    @media (min-width: 651px) {
        display: none;
    }
}
.wistiaDisplayMob, .wistiaDisplayDesk {
    opacity: .9;
}
.textDisplay {
    position: absolute;
    z-index: 123;
    top: 100%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    max-width: none;
    background-color: rgba($blue,.99);
    padding: 40px;
    @media (max-width: 767px) {
        width: 90%;
        padding: 12px;
    }
    h1 {
        font-size: 22px;
        letter-spacing: -1px;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 12px;
        letter-spacing: 10px;
        // text-shadow: 0px 0px 6px rgba(0,0,0,.2);
        @media (min-width: 767px) {
            font-size: 48px;
        }
    }
    h2 {
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 6px;
        // text-shadow: 0px 0px 6px rgba(0,0,0,.2);
        line-height: 1.42;
        margin-bottom: 0;
        @media (min-width: 767px) {
            font-size: 20px;
        }
    }
    .Button {
        background-color: #000;
    }
}
.introContainer {
    padding-bottom: 0;
}
.introText {
    padding-top: 50px;
    max-width: 720px;
}
.appDisplay {
    position: absolute;
    bottom: -36px;
    left: 6px;
    right: 6px;
    @media (min-width: 767px) {
        left: auto;
        bottom: -42px;
        right: 18px;
    }
}

.PolicyHeader {
	ul {
		width: auto;
		margin-left: -12px;
		margin-right: -12px;
		white-space: nowrap;
		overflow: auto;
		@media (min-width: 1024px) {
			flex-wrap: wrap;
		}
		li {
			margin: 0 12px;
			margin-bottom: 12px;
			flex-grow: 1;
			width: auto;
			@media (min-width: 768px) {
				width: auto;
			}
			@media (min-width: 1024px) {
				margin-bottom: 12px;
			}
			a {
				display: inline-block;
				width: 100%;
				text-align: center;
				padding: 12px;
				background-color: #fff;
				box-shadow: $shadow;
				border-radius: 50px;
				@media (min-width: 1024px) {
					border: 0;
				}
			}
		}
	}
}
.Z2HContent {
	padding-top: 60px;
	ol {
		padding-left: 16px;
		li {
			color: #515960;
		    font-weight: 400;
		    line-height: 1.65;
		    margin-top: 0;
		}
		ol {
			list-style: none;
		}
	}
	.videoDisplay {
		margin-bottom: 60px;
	}
	.PolicyContentList {
		li {
			font-weight: 600;
		}
	}
	.PolicyCharList {
		list-style: lower-latin;
		list-style-position: inside;
		li {
			&::marker {
				min-width: 20px;
			}
		}
	}
	ul {
		margin-bottom: 24px;
		list-style: inside;
		li {
			color: #515960;
		    font-weight: 400;
		    line-height: 1.65;
		    margin-top: 0;
		}
	}
	table {
		font-size: 13px;
		border-collapse: collapse;
		border: 1px solid $border;
		max-width: calc(100vw - 32px);
		overflow: auto;
		display: block;
		tr {
			border-bottom: 1px solid $border;
			td, th {
				padding: 12px;
			}
			&:nth-of-type(odd) {
				background-color: #fff;
			}
		}
	}
	.Accordion {
		background-color: #fff;
	}
}
.Z2HFeed {
	> div {
		> a {
			text-align: center;
			background-color: #fff;
			padding-bottom: 10px;
			box-shadow: $shadow;
			img {
				z-index: -1;
			}
			span {
				z-index: 3;
				position: relative;
			}
			&:hover {
				img {
					// transform: scale(1.1);
				}
			}
			&:after {
				display: none;
			}
			&:before {
				content: "";
			    position: absolute;
			    bottom: 0;
			    left: 0;
			    width: 100%;
			    height: 50%;
				background: transparent;
				background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
			}
		}
	}
}
.Gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.GalleryFeedHeader {
    position: relative;
    background-image: url("/img/banner3.jpg");
    background-size: cover;
    background-position: center center;
    padding-top: 80px;
    padding-bottom: 80px;
    &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $blue;
        opacity: .5;
    }
    > div {
        position: relative;
    }
}
.feedTitle {
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
}
.browseHolder {
    margin-left: -16px;
    margin-right: -16px;
    text-align: center;
    button {
        position: relative;
        background-color: #fff;
        margin: 8px;
    }
}
.browseNav {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    padding-left: 16px;
    padding-right: 16px;
    @media (min-width: 768px) {
        justify-content: center;
    }
    li {
        margin: 8px;
        width: calc(50% - 16px);
        @media (min-width: 600px) {
            width: 30%;
        }
        @media (min-width: 768px) {
            width: auto;
        }
        button {
            margin: 0;
            border-width: 2px;
            width: 100%;
            // border: 1px solid #fff !important;
            // background-color: transparent !important;
            // color: #fff !important;
            // font-weight: 500 !important;
            @media (min-width: 768px) {
                width: auto;
            }
        }
        &[aria-selected="true"] button {
            border: 2px solid $peach;
            &:after {
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(252, 149, 75, 0);
                border-top-color: $peach;
                border-width: 8px;
                margin-left: -8px;
            }
        }
    }
}
.ButtonGroup {
    button {
        background-color: transparent;
        border-color: #fff;
        color: #fff;
    }
}
.browseButtonGroup {
    @media (max-width: 768px) {
        white-space: nowrap;
        overflow: auto;
    }
}
.Z2HTitle {
    margin-top: 30px;
}
.GalleryContainer {

}
.GalleryItem {
    padding: 12px;
    width: 25%;
    @media (max-width: 991px) {
        width: calc(100% / 3);
    }
    @media (max-width: 767px) {
        width: 50%;
        padding: 8px;
    }
    a {
        display: block;
        position: relative;
        width: 100%;
        > div {
            
        }
        > span {
            border-radius: $radius;
        }
        img {
            object-fit: cover;
        }
        h1,h2,h3,h4,h5,h6 {

        }
        p {

        }
    }
}
.GalleryHeader {
    width: 100%;
}
.GalleryHeaderActions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 0 12px 0;
    > button {
        @media (max-width: 768px) {
            width: 100%;
        }
    }
}
.GalleryFeed {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
    @media (max-width: 767px) {
        width: calc(100% + 16px);
        margin-right: -8px;
        margin-left: -8px;
    }
}
.GalleryFeedItem {
    opacity: 1;
    transition: opacity 0.5s ease-out;
    padding: 12px;
    padding-top: 12px !important;
    position: relative;
    width: 100%;
    display: flex;
    border-radius: $radius;
    margin-bottom: 25px;
    align-items: center;
    box-shadow: $shadow;
    border-radius: $radius;
    background-color: #fff;
    @media (max-width: 991px) {
        width: calc(100% / 1);
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 8px;
        padding-top: 8px !important;
        flex-direction: column;
        justify-content: flex-start;
    }
    .GalleryFeedItemText {
        padding-left: 25px;
        padding-right: 25px;
        width: 70%;
        h3 {
            font-weight: 600;
            font-size: 20px;
        }
        a {
            width: auto !important;
            display: inline-block;
        }
        p {
            @media (max-width: 767px) {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    a {
        border-radius: $radius;
        overflow: hidden;
        display: block;
        position: relative;
        width: 30%;
        max-width: 300px;
        box-shadow: none !important;
        @media (max-width: 767px) {
            width: 100%;
        }
        &:before, &:after {

        }
        > div {
            padding-bottom: 130%;
            position: relative;
            > img {
                position: absolute;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }
    header {
        position: absolute;
        right: 0; bottom: 0; left: 0;
        padding: 12px;
        text-align: center;
        z-index: 2;
        @media (min-width: 992px) {
            padding: 32px 12px 18px 12px;
        }
        p {
            color: #fff;
            text-transform: uppercase;
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 1px;
        }
        h1,h2,h3,h4,h5,h6 {
            margin: 6px 0;
        }
        span {
            color: #fff;
            font-size: 13px;
            display: none;
        }
    }
}
.GalleryFeedItemQuanity {
    display: flex;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    border: 1px solid $border;
    overflow: hidden;
    span {
        display: block;
        flex-grow: 1;
        text-align: center;
        border-left: 1px solid $border;
        border-right: 1px solid $border;
        input {
            border: 0 !important;
            border-radius: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            text-align: center;
            -webkit-appearance: textfield !important;
            -moz-appearance: textfield !important;
            appearance: textfield !important;
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
        }
    }
    button {
        outline: 0;
        box-shadow: none;
        border: 0;
        display: block;
        min-width: 50px;
        font-size: 20px;
        &:hover {
            background-color: $back;
        }
    }
}
.GalleryProductItem {
    a {
        height: 100%;
        padding: 12px;
        background-color: #fff;
        box-shadow: $shadow;
        &:after {
            background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#ffffff));
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
        }
        > span {
            border-radius: 18px;
        }
        > div {
            padding-bottom: 100%;
            position: relative;
            > img {
                position: absolute;
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
    }
    header {
        position: relative;
        background-color: #fff;
        padding: 18px 0 6px 0;
        p {
            color: $blue;
            font-weight: 500;
        }
        h1,h2,h3,h4,h5,h6 {
            color: $blue;
        }
    }
}

