@import "vars.scss";
.Accordion {
    width: 100%;
    margin-bottom: 16px;
    border: 1px solid #000;
    border-radius: $radius;
    > button {
        display: block;
        padding: 18px;
        width: 100%;
        position: relative;
        text-align: left;
        border: none;
        outline: 0;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        background-image: url(/img/icons/plus-regular.svg);
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: right 18px center;
    }
    > div {
        display: none;
        padding: 20px;
        border-top: 1px solid $border;
        p {
            margin: 0;
            a {
                color: #000;
                font-weight: 500;
            }
        }
    }
    &.open {
        > button {
            background-image: url(/img/icons/minus-regular.svg);
        }
        > div {
            display: block;
        }
    }
}