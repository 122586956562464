@import "vars.scss";
.Home {
    background-color: $back;
    margin-top: 80px;
    padding-top: 60px;
    min-height: 700px;
    @media (max-width: 1080px) {
        margin-top: 70px;
    }
    @media (max-width: 767px) {
        margin-top: 60px;
    }
}
.HomeContent {
    
}
.paddingSection {
  padding: 40px 0;
  @media (max-width: 767px) {
    padding: 40px 0;
  }
}
.homeBanner {
    // background-image: url(/img/banner3.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: auto;
    position: relative;
    &:before {
        content: '';
        // background-image: url(/img/pattern2.png);
        background-color: rgba(0,0,0,.3);
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        display: block;
        background-size: 36px;
        opacity: .9;
    }
    &:after {
        

    }
}
.homeAppBanner {
    position: relative;
    padding: 16px;
    border-radius: $radius;
}
.downloadBanner {
    background-image: url(/img/download.jpg);
    height: 80vh;
    @media (min-width: 767px) {
        height: calc(100vh - 80px);
    }
    &:after {
        display: none;
    }
}
.downloadLogo {
    background-image: url(/img/cc_logo_full-white.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 150px;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
}
.downloadDisplay {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 12px;
    padding-top: 48px;
    background-image: linear-gradient(180deg, transparent, $blue);
}
.homeContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}
.videoDisplay { 
    display: none;
    max-height: 650px;
    overflow: hidden;
    > div {
        @media (min-width: 1155px) {
            margin-top: calc(650px / 2);
            transform: translateY(-50%);
        }
    }
    @media (min-width: 650px) {
        display: block;
    }
}
.videoDisplayMob {
    display: block;
    @media (min-width: 651px) {
        display: none;
    }
}
.imageDisplay { 
    display: none;
    max-height: 650px;
    overflow: hidden;
    > div {
        @media (min-width: 1155px) {
            margin-top: calc(650px / 2);
            transform: translateY(-50%);
        }
    }
    @media (min-width: 650px) {
        display: block;
    }
}
.imageDisplayMob {
    display: block;
    @media (min-width: 651px) {
        display: none;
    }
}
.textDisplay {
    position: absolute;
    z-index: 123;
    top: 50%;
    transform: translateY(-50%);
    max-width: none;
    h1 {
        font-size: 32px;
        letter-spacing: -1px;
        font-weight: 800;
        text-transform: uppercase;
        @media (min-width: 767px) {
            font-size: 48px;
        }
    }
    .Button {
        background-color: #000;
    }
}
.appDisplay {
    position: absolute;
    bottom: -36px;
    left: 6px;
    right: 6px;
    @media (min-width: 767px) {
        left: auto;
        bottom: -42px;
        right: 18px;
    }
}
.exploreSection {
    .exploreHeader {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;
        }
    }
    h2 {
        padding-right: 12px;
    }
    .exploreBtn {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 8px;
        background-color: transparent;
        color: #000;
    }
}