@import "vars.scss";
.Gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.GalleryFeedHeader {
    position: relative;
    background-image: url("/img/banner3.jpg");
    background-size: cover;
    background-position: center center;
    padding-top: 80px;
    padding-bottom: 80px;
    &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $blue;
        opacity: .5;
    }
    > div {
        position: relative;
    }
}
.feedTitle {
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
}
.browseHolder {
    margin-left: -16px;
    margin-right: -16px;
    text-align: center;
    button {
        position: relative;
        background-color: #fff;
        margin: 8px;
    }
}
.browseNav {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    padding-left: 16px;
    padding-right: 16px;
    @media (min-width: 768px) {
        justify-content: center;
    }
    li {
        margin: 8px;
        width: calc(50% - 16px);
        @media (min-width: 600px) {
            width: 30%;
        }
        @media (min-width: 768px) {
            width: auto;
        }
        button {
            margin: 0;
            border-width: 2px;
            width: 100%;
            // border: 1px solid #fff !important;
            // background-color: transparent !important;
            // color: #fff !important;
            // font-weight: 500 !important;
            @media (min-width: 768px) {
                width: auto;
            }
        }
        &[aria-selected="true"] button {
            border: 2px solid $peach;
            &:after {
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(252, 149, 75, 0);
                border-top-color: $peach;
                border-width: 8px;
                margin-left: -8px;
            }
        }
    }
}
.ButtonGroup {
    button {
        background-color: transparent;
        border-color: #fff;
        color: #fff;
    }
}
.browseButtonGroup {
    @media (max-width: 768px) {
        white-space: nowrap;
        overflow: auto;
    }
}
.searchHolder {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 675px;
    margin: 0 auto;
    > div {
        position: relative;
        width: 100%;
    }
    input {
        border: 2px solid transparent !important;
        border-radius: $radiusM !important;
        padding: 16px 12px !important;
        padding-left: 60px !important;
        &:focus {
            border: 2px solid $cyan !important;
              box-shadow: 0px 0px 100px 0px #000 !important;
        }
    }
    button {
        position: absolute;
        left: 0;
        color: $dark;
        height: 100%;
        padding: 0 24px;
    }
    button.resetBtn {
        left: auto;
        right: 0;
        font-size: 18px;
    }
}
.GalleryContainer {

}
.GalleryItem {
    padding: 12px;
    width: 25%;
    @media (max-width: 991px) {
        width: calc(100% / 3);
    }
    @media (max-width: 767px) {
        width: 50%;
        padding: 8px;
    }
    a {
        display: block;
        position: relative;
        width: 100%;
        > div {
            
        }
        > span {
            border-radius: $radius;
        }
        img {
            object-fit: cover;
        }
        h1,h2,h3,h4,h5,h6 {

        }
        p {

        }
    }
}
.GalleryHeader {
    width: 100%;
}
.GalleryHeaderActions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 0 12px 0;
    > button {
        @media (max-width: 768px) {
            width: 100%;
        }
    }
}
.GalleryFeed {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
    @media (max-width: 767px) {
        width: calc(100% + 16px);
        margin-right: -8px;
        margin-left: -8px;
    }
}
.GalleryFeedItem {
    opacity: 1;
    transition: opacity 0.5s ease-out;
    padding: 12px;
    padding-top: 12px !important;
    position: relative;
    width: 25%;
    @media (max-width: 991px) {
        width: calc(100% / 3);
    }
    @media (max-width: 767px) {
        width: 50%;
        padding: 8px;
        padding-top: 8px !important;
    }
    .favContainer {
        position: absolute;
        top: 12px;
        right: 12px;
        width: calc(100% - 24px);
        text-align: right;
    }
    a {
        border-radius: $radius;
        overflow: hidden;
        display: block;
        position: relative;
        width: 100%;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(49, 69, 84, 0)), color-stop(54%, rgba(22, 33, 42, 0.75)), to(#16212a));
            background-image: linear-gradient(to bottom, rgba(49, 69, 84, 0), rgba(22, 33, 42, 0.75) 54%, #16212a);
            z-index: 1;
        }
        > div {
            padding-bottom: 130%;
            position: relative;
            > img {
                position: absolute;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }
    header {
        position: absolute;
        right: 0; bottom: 0; left: 0;
        padding: 12px;
        text-align: center;
        z-index: 2;
        @media (min-width: 992px) {
            padding: 32px 12px 18px 12px;
        }
        p {
            color: #fff;
            text-transform: uppercase;
            margin: 0;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 1px;
        }
        h1,h2,h3,h4,h5,h6 {
            margin: 6px 0;
        }
        span {
            color: #fff;
            font-size: 13px;
            display: none;
        }
    }
}
.GalleryFeedItemQuanity {
    display: flex;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    border: 1px solid $border;
    overflow: hidden;
    span {
        display: block;
        flex-grow: 1;
        text-align: center;
        border-left: 1px solid $border;
        border-right: 1px solid $border;
        input {
            border: 0 !important;
            border-radius: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            text-align: center;
            -webkit-appearance: textfield !important;
            -moz-appearance: textfield !important;
            appearance: textfield !important;
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
        }
    }
    button {
        outline: 0;
        box-shadow: none;
        border: 0;
        display: block;
        min-width: 50px;
        font-size: 20px;
        &:hover {
            background-color: $back;
        }
    }
}
.GalleryProductItem {
    a {
        height: 100%;
        padding: 12px;
        background-color: #fff;
        box-shadow: $shadow;
        &:after {
            background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#ffffff));
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
        }
        > span {
            border-radius: 18px;
        }
        > div {
            padding-bottom: 100%;
            position: relative;
            > img {
                position: absolute;
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
    }
    header {
        position: relative;
        background-color: #fff;
        padding: 18px 0 6px 0;
        p {
            color: $blue;
            font-weight: 500;
        }
        h1,h2,h3,h4,h5,h6 {
            color: $blue;
        }
    }
}

.JournalFeedItem, .CourseItem {
    > a {
        display: block;
        position: relative;
        width: 100%;
        overflow: hidden;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
        header {
            padding: 12px 0;
            white-space: normal;
            p {
                color: #000;
                margin: 0;
                font-size: 12px;
                font-weight: 600;
                &:before {
                    content: '';
                    display: inline-block;
                    height: 1px;
                    width: 40px;
                    background-color: #000;
                    vertical-align: 4px;
                    margin-right: 12px;
                }
            }
            h1,h2,h3,h4,h5,h6 {
                margin: 6px 0;
                color: #000;
            }
            h3 {
                letter-spacing: 0;
                font-size: 18px;
                @media (min-width: 992px) {
                    font-size: 22px;
                }
            }
            span {
                color: $pdesc;
                font-size: 13px;
                font-weight: 400;
                p, h1, h2, h3, h4, h5, h6, strong {
                    color: $pdesc;
                    font-size: 13px;
                    font-weight: 400;
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

.CollectionFeedItem {

    header {
        padding-top: 12px;
    }
}
.CourseItem {
    .CourseImageWrapper {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}
.CourseImageWrapper {
    margin-bottom: 12px;
}
.CourseItem {
    > div {
        overflow: hidden;
        border-radius: 24px;
        background-color: #fff;
        height: 100%;
        display: flex;
        flex-direction: column;
        .GalleryImageWrapper {
            align-self: flex-start;
        }
        .CourseInfo {
            flex-grow: 1;
            justify-content: space-between;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }
    }
}
.CourseInfo {
    padding: 12px;

}
.Filter {
    display: none;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    height: 100%; width: 100%;
    z-index: 99999;
    &.open {
        display: block;
    }
    @media (min-width: 768px) {
        left: auto;
        max-width: 420px;
    }
    @media (min-width: 1081px) {
        max-width: 40%;
    }
}
.FilterOverlay {
    position: fixed;
    background-color: rgba(0,0,0,.5);
    top: 0; right: 0; bottom: 0; left: 0;
    height: 100%; width: 100%;
}
.FilterContent {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: $back;
    form {
        height: calc(100vh - 70px);
        overflow: auto;
    }
}
.FilterHead {
    display: flex;
    justify-content: space-between;
    background-color: $greige;
    padding: 8px 24px;
    height: 70px;
    align-items: center;
}
.FilterItem {
    border-bottom: 1px solid $border;
    &:last-of-type {
        border-bottom: 0;
    }
    .FilterItemHead {
        padding: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-color: transparent;
        h4 {
            flex-grow: 1;
            text-align: left;
            margin: 0;
            span {
                display: inline-block;
                text-align: center;
                border-radius: 50%;
                width: 28px;
                height: 28px;
                background-color: $blue;
                color: #fff;
                margin-left: 8px;
                font-size: 12px;
                line-height: 28px;
                vertical-align: text-bottom;
                vertical-align: bottom;
            }
        }
        > span {
            color: $pdesc;
        }
        img {
            width: 20px;
            height: auto;
            opacity: .4;
            margin-left: 24px;
        }
    }
}
.FilterItemBody {
    padding: 12px 24px;
    display: flex;
    flex-wrap: wrap;
    > div {
        width: 50%;
    }
    label {
        font-size: 18px;
        margin-left: 0;
    }
}
.submitButton {
    position: sticky;
    bottom: 0;
    padding: 24px;
    margin: 0;
    display: flex;
    > span {
        margin-right: 12px;
        svg {
            margin: 0;
        }
    }
}
.FilterResults {
    display: flex;
    padding: 0;
    padding-top: 12px;
    flex-wrap: wrap;
    flex-grow: 1;
    > button {
        margin-bottom: 12px;
        margin-right: 12px;
    }
    > div {
        padding: 6px 12px;
        border: 1px solid #ddd;
        margin-right: 12px;
        margin-bottom: 12px;
        cursor: pointer;
        border-radius: $radius;
        display: flex;
        align-items: center;
        background-color: #fff;
        white-space: nowrap;
        span {
            display: inline-block;
            background-image: url("/img/icons/success-green.svg");
            background-repeat: no-repeat;
            height: 12px;
            width: 12px;
            margin-left: 8px;
            @media (max-device-width: 1440px) {
                background-image: url("/img/icons/delete-red.svg");
            }
        }
        &:hover {
            span {
                background-image: url("/img/icons/delete-red.svg");
            }
        }
    }
    &.inPopup {
        padding: 12px 24px 0px 24px;
        > div {
            margin-left: 0;
            margin-right: 12px;
        }
    }
}
