@import "vars.scss";
$InfoPopBack: #f1f4f9;
$InfoPopBorder: #dae2ef;

.InfoPop {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 6px;
  .InfoPopHover {
    position: relative;
    z-index: 123;
    i {
      width: 14px;
      height: 14px;
      background: #bbb;
      display: block;
      border-radius: 50%;
      background-image: url(/images/file-icons/info-white.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 10px 10px;
      cursor: pointer;
      &:hover {
        background-color: #999;
      }
    }
    .InfoPopContent {
      position: absolute;
      display: none;
      z-index: 123456;
      left: 50%;
      top: calc(100% + 10px);
      transform: translateX(-50%);
      padding: 15px;
      min-width: 250px;
      min-height: 50px;
      background-color: $InfoPopBack;
      border: 1px solid $InfoPopBorder;
      box-shadow: $shadow;
      border-radius: $radius;
      color: #666;
      font-size: 13px;
      line-height: 1.5;
      letter-spacing: 0px;
      white-space: pre-wrap;
      h4 {
        margin-top: 0;
        margin-bottom: 5px;
        color: #444;
      }
      @media (max-width: 767px) {
        position: fixed !important;
        bottom: 0 !important;
        top: auto !important;
        left: 0 !important;
        right: 0 !important;
        transform: none !important;
        width: 100% !important;
        padding-bottom: 50px;
      }
      &:after, &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        @media (max-width: 767px) {
          display: none !important;
        }
      }
      &:after {
        border-color: rgba(241, 244, 249, 0);
        border-bottom-color: $InfoPopBack;
        border-width: 8px;
        margin-left: -8px;
      }
      &:before {
        border-color: rgba(218, 226, 239, 0);
        border-bottom-color: $InfoPopBorder;
        border-width: 9px;
        margin-left: -9px;
      }
      &[position="top"] {
        bottom: calc(100% + 10px);
        top: auto;
        &:after {
          border-color: transparent; border-width: 8px; margin-left: -8px;
          border-top-color: $InfoPopBack;
          bottom: auto; top: 100%;
        }
        &:before {
          border-color: transparent; border-width: 9px; margin-left: -9px;
          border-top-color: $InfoPopBorder;
          bottom: auto; top: 100%;
        }
      }
      &[position="bottom"] {
        top: calc(100% + 10px);
      }
      &[position="left"] {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
        left: auto;
        right: calc(100% + 10px);
        &:after {
          border-color: transparent; border-width: 8px; margin: 0; margin-top: -8px;
          border-left-color: $InfoPopBack;
          bottom: auto; top: 50%;
          right: auto; left: 100%;
        }
        &:before {
          border-color: transparent; border-width: 9px; margin: 0; margin-top: -9px;
          border-left-color: $InfoPopBorder;
          bottom: auto; top: 50%;
          right: auto; left: 100%;
        }
      }
      &[position="right"] {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
        left: calc(100% + 10px);
        &:after {
          border-color: transparent; border-width: 8px; margin: 0;margin-top: -8px;
          border-right-color: $InfoPopBack;
          bottom: auto; top: 50%;
          left: auto; right: 100%;
        }
        &:before {
          border-color: transparent; border-width: 9px; margin: 0; margin-top: -9px;
          border-right-color: $InfoPopBorder;
          bottom: auto; top: 50%;
          left: auto; right: 100%;
        }
      }
    }
    &:hover {
      .InfoPopContent {
        display: block;
      }
    }
  }
}