@import "vars.scss";
.reviewItem {
    display: flex;
    background-color: $greige;
    padding: 16px;
    margin-bottom: 18px;
    border-radius: $radius;
}
.reviewImg {
    background-color: $blue;
    border-radius: 50%;
    margin-right: 18px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    position: relative;
    text-align: center;
    flex-shrink: 0;
    font-weight: 600;
    text-transform: uppercase;
    svg {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #fff;
        fill: $peach;
        color: #fff;
        height: 18px;
        width: 18px;
        line-height: 16px;
        text-align: center;
        border-radius: 50px;
        font-size: 8px;
        border: 2px solid #fff;
    }
}
.reviewUser {
    text-transform: capitalize;
    font-weight: 600;
    color: $blue;
    span {
        font-weight: normal;
        text-transform: none;
        color: $desc;
        font-size: 12px;
        margin-left: 8px;
        font-style: italic;
    }
}
.starHolder {
    position: relative;
    display: inline-block;
    margin-right: 16px;
}
.emptyStars, .fullStars {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    fill: $peach;
    span {
        display: block;
        min-width: 18px;
        width: 18px;
        height: 18px;
    }
}
.fullStars {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
}
.starCount {
    display: inline-block;
}
.starHolder {
    &.avgM {
        .emptyStars, .fullStars {
            span {
                min-width: 24px;
                width: 24px;
                height: 24px;
            }
        }
    }
    &.avgL {
        .emptyStars, .fullStars {
            span {
                min-width: 30px;
                width: 30px;
                height: 30px;
            }
        }
    }
    &.White {
        .emptyStars, .fullStars {
            fill: #fff;
        }
    }
}