@import "vars.scss";
.legalPopupOverlay {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: rgba(0,0,0,.9);
  z-index: 9999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.legalPopup {
  max-width: 480px;
  min-width: 250px;
  padding: 0px;
  background-color: #fff;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  box-shadow: $shadow;
}
.legalPopupBody {
  padding: 36px 18px 18px 18px;
  @media (min-width: 500px) {
    padding: 36px 18px 18px 18px;
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 80px;
    margin-bottom: 12px;
  }
  small a {
    font-weight: bolder;
    text-decoration: underline;
  }
}
.LegalHeading {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #e6e6e6;
}
.LegalButtonGroup {
  display: flex;
  justify-content: center;
  > a, button {
    border: 1px solid #000;
    border-radius: 0;
    padding: 18px 12px;
    text-transform: uppercase;
    flex-grow: 1;
    font-weight: bold;
    font-size: 18px;
    width: 50%;
    text-decoration: none;
  }
  > button {
    background-color: #000;
    color: #fff;
  }
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  padding: 0 18px 24px 18px;
  @media (min-width: 500px) {
    padding: 0 36px 24px 36px;
  }
  > button {
    border: 1px solid #000;
    border-radius: 36px;
    padding: 18px 12px;
    text-transform: uppercase;
    flex-grow: 1;
    font-weight: bold;
    font-size: 18px;
    width: 50%;
    text-decoration: none;
  }
  > .acceptButton {
    background-color: $blue;
    color: #fff;
  }
}
.servicePopup {
    position: fixed;
    left: 0;
    right: 0;
    max-width: none;
    .acceptServiceButton {
      background-color: #000;
      color: #fff;
      max-width: 200px;
      font-size: 13px;
    }
}