@import "vars.scss";
.Footer {

}
.footerContent {
	background-color: $blue;
	padding: 40px 0 20px 0;
}
.footerColumnHolder {
	display: flex;
	flex-wrap: wrap;
}
.footerColumn {
	flex-grow: 1;
	width: 50%;
	padding-bottom: 20px;
	@media (min-width: 768px) {
		width: auto;
	}
	h4 {
		font-weight: bolder;
		color: #fff;
	}
	ul {
		list-style: none !important;
		padding-left: 0 !important;
		li a {
			color: #fff;
		}
	}
}
.logoHolder {
	width: auto;
	height: 30px;
	margin-bottom: 12px;
	img {
		height: 100%;
		width: auto;
		max-width: 100%;
	}
}
.footerTop {
	background-color: #fff;
	padding: 60px 0;
	> div {
		max-width: 500px;
	}
	input {
		border-radius: 0 !important;
	}
	input[type="email"] {
		width: auto !important;
		flex-grow: 1;
		padding: 14px 12px 14px 18px !important;
		border-top-left-radius: $radius !important;
		border-bottom-left-radius: $radius !important;
	}
	input[type="submit"] {
		padding-left: 0;
		transform: none !important;
		border-top-right-radius: $radius !important;
		border-bottom-right-radius: $radius !important;
	}
}
.footerBottom {
	padding: 40px 0;
	background-color: darken($blue, 5%);
	text-align: center;
	p {
		margin: 0;
	    font-family: sans-serif;
    	font-size: 13px;
    	font-weight: 400;
	}
}
.mobilePopup {
	z-index: 999;
	position: fixed;
	bottom: 0; right: 0; left: 0;
	background-color: rgba(255,255,255,.9);
	box-shadow: 0px 0px 20px -2px rgba($blue, 35%);
	backdrop-filter: blur(10px);
	@media (min-width: 767px) {
		display: none;
	}
	.mobilePopupContent {
		padding: 32px 0px 18px 0px;
		p {
			margin-bottom: 0;
			cursor: pointer;
		}
	}
	.mobilePopupTitle {
		font-size: 18px;
	}
	.mobilePopupLogo {
		position: absolute;
		left: 50%;
		height: 60px;
		width: 60px;
		margin-left: -30px;
		top: -30px;
		border: 0px solid #fff;
		background-color: $blue;
		border-radius: 12px;
		background-image: url("/img/favicon-512.jpg");
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		box-shadow: 0px 0px 20px -2px rgba($blue, 35%);
	}
	&.close {
		display: none !important;
	}
}